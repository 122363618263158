import React, { Fragment, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { supplier_List_API, supplierInformationAPI, userHasAccess } from '../../../../api/userUrl';
import { userGetMethod, userDeleteMethod } from '../../../../api/userAction';
import { AddButton, EditButton, DeleteButton, PerPageBox } from '../../../common/GlobalButton';
import Pagination from "react-js-pagination";

export default function ListData(props) {
    const [supplierInformationData, setSupplierInformationData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [hasAccess, setHasAccess] = useState({});
    const [accLoad, setAccLoad] = useState(true);
    const [currentPage, setCurrentPage] = useState();
    const [perPage, setPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [ascDesc, setAscDesc] = useState(false);
    const [sortParam, setSortParam] = useState(1);

    var menuId = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    } else {
        menuId = props.location.state.params.menuId;
    }
    useEffect(() => {
        // ADD,EDIT,DELETE,SHOW ACCESS CHECK
        if (typeof menuId === 'object' && menuId !== null && 'menuId' in menuId) {
                    userGetMethod(`${userHasAccess}/${ menuId.menuId || menuId.menuId.menuId}`)
                        .then(response => {
                            setHasAccess(response.data);
                            setAccLoad(false);
                        });
                } else {
                    userGetMethod(`${userHasAccess}/${menuId}`)
                        .then(response => {
                            setHasAccess(response.data);
                            setAccLoad(false);
                        });
                }
        
        // TABLE DATA READY
        handlePageChange();
    },[]);

    const handleSearchText = (e) =>{
        setSearchText(e);
    }
    const searchHandler = (e) =>{
        setIsLoading(true);
        // userGetMethod(`${supplierInformationAPI}?page=${1}&perPage=${perPage}&searchText=${searchText}`)
        userGetMethod(ascDesc === true ? `${supplier_List_API}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${supplier_List_API}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
        .then(response => {
            setSupplierInformationData(response.data.supplierList.data)
            setCurrentPage(response.data.supplierList.current_page)
                // setPerPage(response.data.supplierList.per_page)
                setTotalData(response.data.supplierList.total)
            setIsLoading(false);
        })
        .catch(error => console.log(error)); 
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          searchHandler();
        }
      };

    const deleteHandler = (itemId, deleteLink) => {
        userDeleteMethod(deleteLink, itemId)
            .then(response => {
                if (response.data.status == 1) {
                    setIsLoading(true);
                    let newData = supplierInformationData.filter(data => data.id != itemId);
                    setSupplierInformationData(newData);
                    setIsLoading(false);
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(error => toast.error(error));
    }

    const handlePageChange = (pageNumber = 1) => {
        setIsLoading(true);
        // TABLE DATA READY
        // userGetMethod(`${supplierInformationAPI}?page=${pageNumber}&perPage=${perPage}&searchText=${searchText}`)
        userGetMethod(ascDesc === true ? `${supplier_List_API}?page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${supplier_List_API}?page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
            .then(response => {
                // console.log("current_page", response.data);
                setCurrentPage(response.data.supplierList.current_page)
                // setPerPage(response.data.supplierList.per_page)
                setTotalData(response.data.supplierList.total)
                setSupplierInformationData(response.data.supplierList.data)
                setIsLoading(false);
            })
            .catch(error => console.log(error))
    }

    const perPageHandler = (e) => {
        // let perPage = e.target.value;
        setIsLoading(true);
        // TABLE DATA READY
        // userGetMethod(`${supplierInformationAPI}?page=${1}&perPage=${perPage}&searchText=${searchText}`)
        userGetMethod(ascDesc === true ? `${supplier_List_API}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${supplier_List_API}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
            .then(response => {
                setCurrentPage(response.data.supplierList.current_page)
                // setPerPage(response.data.supplierList.per_page)
                setTotalData(response.data.supplierList.total)
                setSupplierInformationData(response.data.supplierList.data)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    }
useEffect(() => {
    perPageHandler();
    },[perPage])
   

        useEffect(()=>{
            let ascUrl = '';
            if (ascDesc === true) {
                ascUrl = `${supplier_List_API}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`;
            } else {
                ascUrl = `${supplier_List_API}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`;
            }
            
            setIsLoading(true);
            // TABLE DATA READY
            userGetMethod(ascUrl)
                .then(response => {
                    setCurrentPage(response.data.supplierList.current_page)
                    // setPerPage(response.data.supplierList.per_page)
                    setTotalData(response.data.supplierList.total)
                    setSupplierInformationData(response.data.supplierList.data)
                    setIsLoading(false)
                })
                .catch(error => console.log(error))
        },[ascDesc])

    const sortHandler =(params) => {
        setAscDesc(prevAscDesc => !prevAscDesc);
        setSortParam(params)
    }

    return (
        
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Consumable Supplier Information</h5>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    <div className="input-group text-box searchBox">
                                        <input
                                            type="text"
                                            className="form-control input-txt-bx col-md-4"
                                            placeholder="Type to Search..."
                                            onChange={(e) => handleSearchText(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="input-group-append">
                                            <button 
                                                className="btn btn-primary btn-sm" 
                                                type="button" 
                                                onClick={searchHandler} 
                                            >Go
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6">
                                    <AddButton link="consumableSupplier/add" menuId={menuId} />
                                    {/* <div className="custom-table-pagination m-r-10 pull-right">
                                        <label className="mt-3">
                                            <span>
                                                <select className="form-control pagi-select" onChange={setPerPage(e.target.value)}>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                </select>
                                            </span>
                                        </label>
                                    </div> */}
                                    <PerPageBox pageBoxChange={perPageHandler} perPage={perPage} setPerPage={setPerPage}/>
                                </div>
                            </div>
                                
                            <div className="card-body datatable-react">
                                {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <div className="table-responsive">
                                        <table className="table table-border-horizontal">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="8%" >SL.</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="10%" onClick={() => sortHandler(1)} ><i className="fa fa-sort"></i> Supplier Id</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="15%" onClick={() => sortHandler(2)} ><i className="fa fa-sort"></i> Name</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="15%" onClick={() => sortHandler(3)} ><i className="fa fa-sort"></i> Address</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="10%" onClick={() => sortHandler(4)} ><i className="fa fa-sort"></i> Mobile</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="10%" onClick={() => sortHandler(5)} ><i className="fa fa-sort"></i> Email</th>
                                                    {/* <th scope="col" width="10%" onClick={() => sortHandler(8)} >Bill Config Status</th>
                                                    <th scope="col" width="7%" onClick={() => sortHandler(8)} >Sqr Inch Amount</th>
                                                    <th scope="col" width="7%" onClick={() => sortHandler(8)} >Sqr CM Amount</th> */}
                                                    <th scope="col" width="5%">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { 
                                                    supplierInformationData.length > 0 ? 
                                                        <>
                                                            {supplierInformationData.map((item, index) =>           
                                                                (
                                                                    <tr key={index}>
                                                                        <td scope="row">{ ((index+1) + (currentPage == 1 ? 0 : (currentPage*perPage - perPage))) }</td>
                                                                        <td>{item.id}</td>
                                                                        <td>{item.supplier_name}</td>
                                                                        <td>{item.address}</td>
                                                                        <td>{item.contact_number}</td>
                                                                        <td>{item.email}</td>
                                                                        {/*  state: { params: { menuId: menuId, jobNo: item.job_id, cylinder_no:item.cylinder_id } } */}
                                                                        <td className="">
                                                                            {
                                                                                accLoad === false ? <>
                                                                                    {supplierInformationData.length > 0 ? <EditButton link={`add`}  menuId={{menuId,supplier_id:item.id} } /> : ''} 
                                                                                    {supplierInformationData.length > 0 ? <DeleteButton deleteLink={supplier_List_API} deleteHandler={ deleteHandler } menuId={menuId} dataId={item.id} /> : ''} 
                                                                                </> : ''
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )                
                                                            )}
                                                        </> 
                                                    : <tr><td colSpan="8" className="text-center">No data found</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination 
                                    activePage={currentPage}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={totalData}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    firstPageText="First"
                                    lastPageText="Last"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}