import React, { Fragment , useEffect, useState } from 'react';
import { userGetMethod } from '../../../../api/userAction';
import { JOB_ORDER_RSURL,INVENTORY_PURCHASE_INVOICE_VIEW, INVENTORY_STOCK_ISSUE_VIEW } from '../../../../api/userUrl';
//import './style.scss';

const Show = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [jobOrdersInfo, setJobOrdersInfo] = useState({});
    const [detailsList, setDetailsList] = useState({});
    const [clientStockDetails, setClientStockDetails] = useState([]);

    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    useEffect(()=>{
        // const id = props.match.params.id;
        const id = props.showId;
        // console.log(id)
        userGetMethod(`${INVENTORY_STOCK_ISSUE_VIEW}/${id}`)
        .then(response => {
            // setColorInfo(response.data?.colors);
            setClientStockDetails(response.data.stockIssuProduct?.stockIssueDetails);
            setDetailsList({
                pno_from  : response.data.stockIssuProduct?.pno_from,
                pno_to  : response.data.stockIssuProduct?.pno_to,
                history : response.data.stockIssuProduct,
            })
            // console.log(response.data);
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
    

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className=""> 
                            {/* <ReportHeader reportTtile="Design File" /> */}

                            <div className="mainDiv" style={{"padding": "1% 5%"}}>
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    jobOrdersInfo != null ? (
                                        <>
                                        <div className="row">
                                        <h3>General Information</h3>
                                            <table className="particulars table-stripped groupFont" width="100%"  style={tableStyle}>
                                                <tr>
                                                    <td width="15%">Issue No</td>
                                                    <td width="5%" align="center">:</td>
                                                    <td width="30%">{detailsList.history?.issue_no}</td>
                                                    <td width="15%">Issue Date</td>
                                                    <td width="5%" align="center">:</td>
                                                    <td width="30%">{detailsList.history?.issue_date}</td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td width="15%">PNO From</td>
                                                    <td width="5%" align="center">:</td>
                                                    <td width="30%" colSpan="4">{detailsList.pno_from[0]?.consumeable_user}</td>
                                                </tr>
                                                <tr>
                                                    <td width="15%">PNO To</td>
                                                    <td width="5%" align="center">:</td>
                                                    <td width="30%" colSpan="4">{detailsList.pno_to[0]?.consumeable_user}</td>
                                                </tr>
                                                
                                                
                                            </table>
                                            <h3>Issue List</h3>

                                                <table
                                                    className="table table-bordered"
                                                    style={{ width: "100%" }}
                                                >
                                                    <thead>
                                                    <tr>
                                                        {/* <th scope="col" width="15%">Issue To</th> */}
                                                        {/* <th scope="col" width="20%">Job Ref No</th> */}
                                                        <th scope="col" width="10%">
                                                        Product ID
                                                        </th>
                                                        <th scope="col" width="20%">
                                                        Product Name
                                                        </th>
                                                        <th scope="col" width="10%">
                                                        Unit
                                                        </th>
                                                        <th scope="col" width="25%">
                                                        Unit Price
                                                        </th>
                                                        <th scope="col" width="10%">
                                                        Qty
                                                        </th>
                                                        <th scope="col" width="10%">
                                                        </th>
                                                        
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {clientStockDetails.length > 0 ? (
                                                        <>
                                                        {clientStockDetails.map((item, index) => (
                                                            <tr key={index}>
                                                            <td>{item.product_id}</td>
                                                            <td>{item.product_name || "Unknown"}</td>
                                                            <td>{item.unit?.unit_name}</td>
                                                            <td>{item.unit_price}</td>
                                                            <td>{item.quantity}</td>
                                                            
                                                            </tr>
                                                        ))}
                                                        </>
                                                    ) : (
                                                        <tr>
                                                        <td colSpan="6" className="text-center">
                                                            No data Added
                                                        </td>
                                                        </tr>
                                                    )}
                                                    </tbody>

                                                    <tfoot>
                                                    <tr>
                                                        <td colSpan="4" align="right">
                                                        Total ={" "}
                                                        </td>
                                                        <td>{clientStockDetails.reduce((sum, item) => sum + Number(item.quantity), 0)}</td>
                                                        <td></td>
                                                        
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            <div className="row m-t-10 m-l-10">
                                            <fieldset className="border" style={{ width: "98%" }}>
                                                {/* <legend className="w-auto text-left">
                                                Added Invoice
                                                </legend> */}
                                                <div className="col-md-12">
                                                </div>
                                            </fieldset>
                                            </div>
                                        </div>
                                        </>
                                    ):(
                                        <div className="text-center">
                                            No Data Found
                                        </div>
                                    )
                                    
                                )
                            } 
                            </div>  
                        </div>
                        
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default Show;