import React, { Fragment , useEffect, useState } from 'react';
import useForm from "react-hook-form";
import { SubmitButton } from '../../../../common/GlobalButton'
import moment from 'moment';

const Form = (props) => {
    const { handleSubmit, register, errors } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [reportType, setReportType] = useState('upto_date');
    const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));

    var menuId = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    }else{
        menuId = props.location.state.params.menuId;
    }
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    function onChangeValue(event) {
        setReportType(event.target.value);
    }
    const submitHandler = (data, e) => {
        let url = `${process.env.PUBLIC_URL}/consumableCurrentStoctReport?toDate=${fromDate}`;
        window.open(url, '_blank', 'height=800,width=1200');
    }
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Current Stock Report Form</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(submitHandler)} className="needs-validation theme-form">
                                    <div className="form-group row">
                                        <div className="col-lg-4 col-md-4 offset-sm-4">
                                        <div className='d-flex justify-content-between'>
                                            <p className="col-form-label mr-2" style={{fontWeight:'bold'}}>Date:</p>
                                                        <input 
                                                            className="form-control"
                                                            id="to_date" 
                                                            name="to_date" 
                                                            type="date"
                                                            value={fromDate}
                                                            onChange={(e)=>setFromDate(e.target.value)}
                                                            ref={register({
                                                                required: 'From Date Field Required'
                                                            })}
                                                        />
                                                    {errors.to_date && <p className='text-danger'>{errors.to_date.message}</p>}
                                        </div>
                                        </div>
                                            <div className="col-sm-4 col-md-6 col-lg-6 offset-sm-4" 
                                            onChange={onChangeValue}
                                            >
                                                <input 
                                                    name="upto_date" 
                                                    value="upto_date" 
                                                    id="upto_date" 
                                                    type="radio" 
                                                    checked={reportType === "upto_date"}
                                                />
                                                <label className="col-form-label" htmlFor="upto_date" style={{marginLeft:"10px"}}>Upto date</label>

                                                {/* <input 
                                                    name="voucher_type" 
                                                    value="date_range" 
                                                    id="date_range" 
                                                    type="radio" 
                                                    className="m-l-10"
                                                    checked={reportType === "date_range"}
                                                />
                                                <label className="col-form-label" htmlFor="date_range">Date Range</label> */}
                                            </div>
                                    </div>
                                    <SubmitButton link="#" offset="4" menuId={ menuId }/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default Form;