import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
// Function to format employee names (limit to 5 chars + dot if longer)
const formatEmployeeName = (name) => name.length > 5 ? name.slice(0, 5) + "." : name;

const HorizontalBarChart = ({data}) => {
  return (
    <div style={{ width: "100%", height: 400, overflowY: "auto" }}> {/* Scrollable Container */}
      <ResponsiveContainer width="100%" height={data.length * 30}> {/* Dynamically adjust height */}
        <BarChart
          layout="vertical"
          data={data}
        //   margin={{ top: 20, right: 30, left: 100, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis 
            dataKey="employee_name" 
            type="category" 
            width={100} 
            interval={0}
            tickFormatter={formatEmployeeName}
          />
          <Tooltip />
          <Legend />
          <Bar dataKey="total_jobs" fill="#29B6F6" name="Total Jobs" />
        </BarChart>
      </ResponsiveContainer>
    </div>
    
  );
};

export default HorizontalBarChart;
