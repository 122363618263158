import React, { Fragment , useEffect, useReducer, useState } from 'react';
import ReportHeader from './ReportHeader';
// import {FACTORY_PRODUCTION_REPORT} from '../../../../api/userUrl'
import { userGetMethod } from '../../../../../api/userAction';
import './style.scss';
import { CURRENT_STOCK_REPORT, DEPARTMENTWISE_DETAILS_REPORT, MAINTAINENACE_SUMMARY_REPORT, PRODUCT_MOVEMENT_REPORT, ProductWiseSummaryReport } from '../../../../../api/userUrl';
import { useLocation } from 'react-router-dom';

const Report = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataLoad, setDataLoad] = useState(true);
    const [totalFactoryProduction, setTotalFactoryProduction] = useState({});
    const [grindings, setGrindings] = useState([]);
    const [engravings, setEngravings] = useState([]);
    const [chromes, setChromes] = useState([]);
    const [layouts, setLayouts] = useState([]);
    const [platings, setPlatings] = useState([]);
    const [polishings, setPolishings] = useState([]);
    const [challans, setChallans] = useState([]);
    const [resultData, setResultData] = useState([]);
    
    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    // const fromDate = props.match.params.toDate;
    // const toDate = props.match.params.toDate;
    const oneDay = 24 * 60 * 60 * 1000;
    const location = useLocation();

  // Parse the query string using URLSearchParams
  const queryParams = new URLSearchParams(location.search);
  const toDate = queryParams.get('to_date');   
  const fromDate = queryParams.get('from_date');   
  const type = queryParams.get('type');   
  const consumableUser = queryParams.get('consumeable_user');   
  
    useEffect(()=>{
        userGetMethod(`${PRODUCT_MOVEMENT_REPORT}?type=${type}&from_date=${fromDate}&to_date=${toDate}`) 
        .then(response => {
            console.log('response', response.data);
            setTotalFactoryProduction(response.data?.stocks)
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
    

// console.log("totalFactoryProduction",totalFactoryProduction)
// console.log(datas)

    
    return (
        // <Fragment>
        //     <div className="container-fluid">
        //         <div className="row">
        //             <div className="col-sm-12">
        //                 <div className=""> 
        //                     <ReportHeader totalFactoryProduction={totalFactoryProduction} />

        //                     <div className="mainDiv" style={{"padding": "1% 5%"}}>
        //                     {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
        //                         (
        //                             <>
        //                                 <div className="text-center">
        //                                     <h5>{'Product Wise Summary Report '}</h5>
        //                                 </div>
        //                                 <Fragment>
        //                                     <div className="row">
        //                                         <table className="particulars table table-bordered table-stripped reportBody" cellSpacing="5" cellPadding="5" width="100%"  style={tableStyle}>
        //                                             <thead>    
        //                                                 <tr>
        //                                                     <th style={{fontSize:"15px",fontWeight:"bold"}} className="text-center">ProductID</th>
                                                            
        //                                                     <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">Description</th>
        //                                                     <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">Quantity</th>
        //                                                     <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">Unit</th>
        //                                                     <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">Unit Price</th>
        //                                                     <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">Total</th>
                                                              
        //                                                 </tr>
                                                            
                                                          
        //                                             </thead>
        //                                             {isLoading ?
        //                                             (<p>loading...</p>)
        //                                             :
        //                                             (
                                                        
        //                                                <>
        //                                                {totalFactoryProduction.length> 0 ? 
        //                                                 (
        //                                                     totalFactoryProduction.map((item,index)=>
                                                                
                                                                  
                                                                
        //                                                             <>
        //                                                             <thead>
                                                                
        //                                                         <td id='dateId' style={{ fontSize:"13px",fontWeight:"bold"}}  key={index}>{item.product?.product_id}</td>
        //                                                         <td style={{ fontSize:"13px",fontWeight:"bold"}}  key={index}>{item.product?.product_name}</td>
        //                                                         <td style={{ fontSize:"13px",fontWeight:"bold",textAlign:'center'}}  key={index}>{item.stock_in}</td>
        //                                                         <td style={{ fontSize:"13px",fontWeight:"bold",textAlign:'center'}}  key={index}>{item.unit}</td>
        //                                                         <td style={{ fontSize:"13px",fontWeight:"bold",textAlign:'right'}}  key={index}>{item.unit_price}</td>
        //                                                         <td style={{ fontSize:"13px",fontWeight:"bold",textAlign:'right'}}  key={index}>{item.unit * item.unit_price}</td>
                                                            
                                                            
        //                                                             </thead>
    
                                                                    
    
        //                                                             </>
        //                                                     )
                                                        
        //                                                 )
        //                                                 :"There is no data in current stock"}
        //                                                </>
                                                       
                                                
        //                                         )}
                                                
        //                                         </table>
        //                                     </div>    
        //                                 </Fragment>
        //                             </>
        //                         )
        //                     } 
        //                     </div>  
        //                 </div>    
        //             </div>
        //         </div>
        //     </div>
        // </Fragment>
        <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div>
            <ReportHeader totalFactoryProduction={totalFactoryProduction} />
              <div className="text-center">
                <h5 style={{fontWeight:'bold'}}>{'Product Movement Report '}</h5>
              </div>
              <div className="mainDiv" style={{ padding: "1% 5%" }}>
                {isLoading ? (
                  <img src={process.env.PUBLIC_URL + "/preloader.gif"} alt="Data Loading" />
                ) : (
                  <Fragment>
                    <table className="table table-bordered" style={tableStyle}>
                      {/* Table Header */}
                      <thead>
                        <tr>
                          <th rowSpan="2">Ref No</th>
                          <th rowSpan="2">Tr Date</th>
                          <th rowSpan="2">Status</th>
                          <th rowSpan="2">User</th>
                          <th colSpan="3">IN</th>
                          <th colSpan="3">OUT</th>
                          <th colSpan="3">Cum Closing Balance</th>
                        </tr>
                        <tr>
                          <th>Quantity</th>
                          <th>Rate</th>
                          <th>Amt</th>
                          <th>Quantity</th>
                          <th>Rate</th>
                          <th>Amt</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amt</th>
                        </tr>
                      </thead>

                      {/* Table Body */}
                      <tbody>
                        {/* Opening Balance */}
                        <tr>
                          <td colSpan="3" style={{ fontWeight: "bold", textAlign: "left" }}>Opening</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>

                        {/* Transaction Rows */}
                        {totalFactoryProduction.length > 0 ? (
                          totalFactoryProduction.map((item, index) => (
                            <tr key={index}>
                              <td>{item.slip_no}</td>
                              <td>{item.tr_date}</td>
                              <td>{item.status}</td>
                              <td>{item.user || 'Admin'}</td>
                              <td>{item.stock_in}</td>
                              <td>{item.unit_price}</td>
                              <td style={{textAlign:'right'}}>{item.stock_in * item.unit_price}</td>
                              <td>{item.stock_out || 0}</td>
                              <td>{item.unit_price}</td>
                              <td style={{textAlign:'right'}}>{item.stock_out ? item.stock_out * item.unit_price : 0}</td>
                              <td>{item.stock_in - (item.stock_out || 0)}</td>
                              <td>{item.unit_price}</td>
                              <td style={{textAlign:'right'}}>{(item.stock_in - (item.stock_out || 0)) * item.unit_price}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="13" className="text-center">There is no data in current stock</td>
                          </tr>
                        )}

                        {/* Grand Total Row */}
                        <tr style={{ fontWeight: "bold" }}>
                          <td colSpan="4" style={{ textAlign: "right" }}>Grand Total:</td>
                          <td style={{textAlign:'right'}}>{totalFactoryProduction.reduce((sum, item) => sum + item.stock_in, 0).toFixed(2)}</td>
                          <td></td>
                          <td style={{textAlign:'right'}}>{totalFactoryProduction.reduce((sum, item) => sum + (item.stock_in * item.unit_price), 0).toFixed(2)}</td>
                          <td style={{textAlign:'right'}}>{totalFactoryProduction.reduce((sum, item) => sum + (item.stock_out || 0), 0).toFixed(2)}</td>
                          <td></td>
                          <td style={{textAlign:'right'}}>{totalFactoryProduction.reduce((sum, item) => sum + ((item.stock_out || 0) * item.unit_price), 0).toFixed(2)}</td>
                          <td style={{textAlign:'right'}}>{totalFactoryProduction.reduce((sum, item) => sum + (item.stock_in - (item.stock_out || 0)), 0).toFixed(2)}</td>
                          <td></td>
                          <td style={{textAlign:'right'}}>{totalFactoryProduction.reduce((sum, item) => sum + ((item.stock_in - (item.stock_out || 0)) * item.unit_price), 0).toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
    )
};

export default Report;