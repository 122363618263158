import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

const BarChartComponent  = ({data}) => {

  const currentYear = data[0]?.current_year || "Current Year";
  const previousYear = data[0]?.previous_year || "Previous Year";

  const formattedData = data.map(item => ({
    ...item,
    previous_monthly_total_surface_area: parseFloat(item.previous_monthly_total_surface_area).toFixed(2),
    current_monthly_total_surface_area: parseFloat(item.current_monthly_total_surface_area).toFixed(2),
  }));

  const maxValue = Math.max(...formattedData.map(d => parseFloat(d.previous_monthly_total_surface_area)));
  const roundedMaxValue = Math.ceil(maxValue / 1000000) * 1000000; // Rounds to the nearest million

  return (
    <div style={{ width: "100%", height: 400 }}>
      {/* <h3 style={{ textAlign: "center" }}>Last 2 Year Board</h3> */}
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={formattedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="month" tickFormatter={(month) => month.substring(0, 3) + "."} />
          <YAxis 
            tickFormatter={(value) => Number(value).toLocaleString()}
            domain={[0, roundedMaxValue]} 
          />
          <Tooltip />
          <Legend />
          <Bar dataKey="current_monthly_total_surface_area" fill="#1565C0" name={currentYear} />
          <Bar dataKey="previous_monthly_total_surface_area" fill="#29B6F6" name={previousYear} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
