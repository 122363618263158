import React, { Fragment, useState, useEffect, useReducer } from 'react';
import useForm from "react-hook-form";
import { SubmitButton } from '../../../common/GlobalButton';
import { userGetMethod, userPostMethod, userPutMethod } from '../../../../api/userAction';
import { INVENTORY_LIST_URL,INVENTORY_PRODUCT_TYPE } from '../../../../api/userUrl';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { trStyleNormal } from '../jobAgreement/Create';

const Add = (props) => {
    const { handleSubmit, register, errors } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [resource, setResource] = useState(false);
    const [productTypes, setProductTypes] = useState('');
    const [typeHeadOptions, setTypeHeadOptions] = useState({});
    const [dropDownData, setDropdownData] = useState();
    const [file,setFile] = useState();
    const [jobNoValue,setJobNoValue] = useState({
        product_type:'',
        

    });
    const [productId ,setProductId] = useState('');

    
    var menuId = 0;
    let product_type = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    }else{
        menuId = props.location.state.params.menuId;
        product_type = props.location.state.params.menuId.product_type;
    }
    
    useEffect(()=>{
        setIsLoading(true);
        if (product_type !== 0) {
            userGetMethod(`${INVENTORY_PRODUCT_TYPE}/${product_type}/edit`)
               .then(response => {
                // console.log(response.data);

                setJobNoValue(response.data.ConsumeableProductType);
                setIsLoading(false);
               })
               .catch(error => console.log(error))
        }
               
    }, [product_type]);
    
    const clientStocksInputHander = (event) => {
        const { name, value } = event.target;
        setJobNoValue((prevState) => ({
            ...prevState, 
            [name]: value,
        }));
        
    }
    
  
    const submitHandler = (data, e) => {
        if (product_type !== 0 && product_type !== undefined) {
            userPutMethod(`${INVENTORY_PRODUCT_TYPE}/${product_type}`, data)
            .then(response => {
                console.log("response.data", response.data);
                if (response.data.status == 1) {
                    toast.success(response.data.message)
                    clearForm();
                    e.target.reset();
                } else {
                    toast.error(response.data.message)
                }
            })
        .catch(error => toast.error(error))
        }else{
        userPostMethod(INVENTORY_PRODUCT_TYPE, data)
            .then(response => {
                console.log("response.data", response.data);
                if (response.data.status == 1) {
                    toast.success(response.data.message)
                    clearForm();
                    e.target.reset();
                } else {
                    toast.error(response.data.message)
                }
            })
        .catch(error => toast.error(error))
        }
    }

    const clearForm = () => {
        setJobNoValue({
            product_type:'',
    
        })
    }
    
    return (
        
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Consumable Registration</h5>
                                    </div>
                                    <div className="col-md-6">
                                        {/* <PanelRefreshIcons panelRefresh={pageRefreshHandler} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                <form onSubmit={handleSubmit(submitHandler)} className="needs-validation theme-form">
                                    <div className="row">
                                        <div className="col-md-8" style={{marginTop: '20px'}}>
                                            {/* <div className="form-group row">
                                                <label className="col-sm-3 col-form-label required" htmlFor="job_no">Product ID</label>
                                                <div className="col-sm-9">
                                                    <Typeahead
                                                        id="job_order_id"
                                                        name="job_order_id"
                                                        labelKey={option => `${option.name}`}
                                                        options={typeHeadOptions['job_orders']}
                                                        placeholder="Select Job No..."
                                                        onChange={(e) => dropDownChange(e, 'job_order_id')}
                                                        selected={jobNoValue}
                                                        disabled={job_order_id != null ? 'disabled' : ''}
                                                        ref={register({
                                                            required: 'Job No Field Required'
                                                        })}
                                                    />
                                                    {errors.job_order_id && 'Job No. is required'}
                                                </div>
                                            </div> */}

                                            

                                            <div className="form-group row mt-2">
                                                <label className="col-sm-3 col-form-label" htmlFor="product_type">Product Type</label>
                                                <div className="col-sm-5">
                                                    <input className="form-control" id="product_type" value={jobNoValue?.product_type || ''} name="product_type" onChange={clientStocksInputHander} type="text" placeholder="product type name" ref={register({})} />
                                                </div>
                                            </div>
                                            
                                           
                                           
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                        </div>
                                    </div>
                                    <SubmitButton link="consumableProductType/index" offset="4" menuId={ menuId }/>
                                </form>
                                )
                            }
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Add;