import React, { Fragment , useEffect, useReducer, useState } from 'react';
import ReportHeader from './ReportHeader';
// import {FACTORY_PRODUCTION_REPORT} from '../../../../api/userUrl'
import { userGetMethod } from '../../../../../api/userAction';
import './style.scss';
import { CURRENT_STOCK_REPORT, DEPARTMENTWISE_DETAILS_REPORT, MAINTAINENACE_SUMMARY_REPORT, PRODUCT_INVOICE_REPORT, ProductWiseSummaryReport } from '../../../../../api/userUrl';
import { useLocation } from 'react-router-dom';

const Report = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataLoad, setDataLoad] = useState(true);
    const [totalFactoryProduction, setTotalFactoryProduction] = useState({});
    const [grindings, setGrindings] = useState([]);
    const [engravings, setEngravings] = useState([]);
    const [chromes, setChromes] = useState([]);
    const [layouts, setLayouts] = useState([]);
    const [platings, setPlatings] = useState([]);
    const [polishings, setPolishings] = useState([]);
    const [supplierDetails, setSupplierDetails] = useState([]);
    const [resultData, setResultData] = useState([]);
    
    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    // const fromDate = props.match.params.toDate;
    // const toDate = props.match.params.toDate;
    const oneDay = 24 * 60 * 60 * 1000;
    const location = useLocation();

  // Parse the query string using URLSearchParams
  const queryParams = new URLSearchParams(location.search);
  const toDate = queryParams.get('to_date');   
  const fromDate = queryParams.get('from_date');   
  const type = queryParams.get('type');   
  const consumableUser = queryParams.get('consumeable_user');   
  console.log("Hello")
  
    useEffect(()=>{
        userGetMethod(`${PRODUCT_INVOICE_REPORT}?type=${type}&from_date=${fromDate}&to_date=${toDate}`) 
        .then(response => {
            console.log('response', response.data);
            setTotalFactoryProduction(response.data?.purchaseInvoice)
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
    

// console.log("totalFactoryProduction",totalFactoryProduction)
// console.log(datas)
// const grandTotal = totalFactoryProduction.reduce(
//     (sum, item) => sum + item.stock_in * item.unit_price,
//     0
//   ).toFixed(2);

//   // Function to convert amount to words
//   const numberToWords = (num) => {
//     const words = require("number-to-words");
//     return words.toWords(num);
//   };

    
    return (
        <Fragment>
        <div className="container-fluid">
            <div className="row">
            <div className="col-sm-12">
                <div>
                <ReportHeader totalFactoryProduction={totalFactoryProduction} />
                <div className="text-center">
                    <h5 style={{fontWeight:'bold'}}>{'Supplier Invoice Report'}</h5>
                </div>
                <div className="mainDiv" style={{ padding: "1% 5%" }}>
                    {isLoading ? (
                    <img src={process.env.PUBLIC_URL + "/preloader.gif"} alt="Data Loading" />
                    ) : (
                        <Fragment>
                        {totalFactoryProduction.length > 0 ? (
                          totalFactoryProduction.map((invoice, index) => {
                            // Calculate Grand Total for the Invoice
                            const grandTotal = invoice.details
                              .reduce((sum, item) => sum + item.quantity * item.unit_price, 0);
    
                            return (
                              <div key={index} style={{ marginBottom: "20px", borderBottom: "2px solid black" }}>
                                {/* Supplier & Invoice Details */}
                                <table className="table" style={tableStyle}>
                                  <tbody>
                                    <tr>
                                      <td colSpan="3"><strong>Name of the Supplier:</strong> {invoice.supplier_info?.supplier_name}</td>
                                      <td><strong>Inv No:</strong> {invoice.invoice_no}</td>
                                    </tr>
                                    <tr>
                                      <td colSpan="3"><strong>Address:</strong> {invoice.supplier_info?.address}</td>
                                      <td><strong>Date:</strong> {invoice.invoice_date}</td>
                                    </tr>
                                    <tr>
                                      <td colSpan="3"><strong>Contact Details:</strong> {invoice.contact}</td>
                                      <td><strong>Voucher Ref:</strong> {invoice.voucher_ref || "N/A"}</td>
                                    </tr>
                                    <tr>
                                      <td colSpan="3"><strong>Supplier ID:</strong> {invoice.supplier_info?.id}</td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
    
                                {/* Product Table */}
                                <table className="table table-bordered" style={tableStyle}>
                                  <thead>
                                    <tr>
                                      <th>Product ID</th>
                                      <th>Description</th>
                                      <th>Quantity</th>
                                      <th>Unit</th>
                                      <th>Unit Price</th>
                                      <th>Total (Tk)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {invoice.details.map((item, prodIndex) => (
                                      <tr key={prodIndex}>
                                        <td>{item.id}</td>
                                        <td style={{ textAlign: "left", whiteSpace: "pre-line" }}>{item.product_details || "Unknown"}</td>
                                        <td>{item.quantity.toFixed(2)}</td>
                                        <td>{item.unit}</td>
                                        <td style={{textAlign:"right"}}>{item.unit_price.toFixed(2)}</td>
                                        <td style={{textAlign:"right"}}>{(item.quantity * item.unit_price).toFixed(2)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
    
                                {/* Grand Total and Amount in Words */}
                                <table className="table" style={tableStyle}>
                                  <tbody>
                                    <tr>
                                      <td colSpan="6">
                                        <strong>In Words:</strong>  Tk. Only
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="5" style={{ textAlign: "right" }}>
                                        <strong>Grand Total (Tk.)</strong>
                                      </td>
                                      <td style={{textAlign:"right"}}><strong>{grandTotal}</strong></td>
                                    </tr>
                                    <tr>
                                      <td colSpan="6"><strong>Note:</strong></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          })
                        ) : (
                          <p className="text-center">There is no data available</p>
                        )}
                      </Fragment>
                    )}
                </div>
                </div>
            </div>
            </div>
        </div>
        </Fragment>
    )
};

export default Report;