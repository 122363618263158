import React, { Fragment, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ENGRAVING_RS_URL, userHasAccess } from '../../../../api/userUrl';
import { userGetMethod, userDeleteMethod } from '../../../../api/userAction';
import { AddButton, EditButton, DeleteButton, PerPageBox, PanelRefreshIcons } from '../../../common/GlobalButton';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { FaFileAlt } from "react-icons/fa";
import { Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';

export default function ListData(props) {
    const [cylinderData, setCylinderData] = useState([]);
    const [status, setStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [hasAccess, setHasAccess] = useState({});
    const [accLoad, setAccLoad] = useState(true);
    const [currentPage, setCurrentPage] = useState();
    const [perPage, setPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [engravingStatus, setEngravingStatus] = useState(0);
    const [ascDesc, setAscDesc] = useState(false);
    const [jobActiveStatus, setJobActiveStatus] = useState(0);
    const [sortParam, setSortParam] = useState(1);
    const [shiftExit, setShiftExit] = useState({});

    var menuId = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    } else {
        menuId = props.location.state.params.menuId;
    }
    
    useEffect(() => {
        // ADD,EDIT,DELETE,SHOW ACCESS CHECK
        userGetMethod(`${userHasAccess}/${menuId}`)
            .then(response => {
                setHasAccess(response.data);
                setAccLoad(false);
            });
        
        // TABLE DATA READY
        pageChange();
    },[]);

    useEffect(() => {
        perPageBoxChange();
    },[jobActiveStatus,perPage])

    const handleSearchText = (e) => {
        setSearchText(e);
    }
    const searchHandler = (e) => {
        setIsLoading(true);
        userGetMethod(ascDesc === true ? `${ENGRAVING_RS_URL}?engraving_status=${jobActiveStatus}&page=${1}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${ENGRAVING_RS_URL}?engraving_status=${jobActiveStatus}&page=${1}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
        .then(response => {
            setCurrentPage(response.data.allCylinders.current_page)
            // setPerPage(response.data.allCylinders.per_page)
            setStatus(response.data.engraving_status)
            setTotalData(response.data.allCylinders.total)
            setCylinderData(response.data.allCylinders.data)
            setEngravingStatus(response.data.engraving_status)
            setShiftExit(response.data.shiftExist)
            setIsLoading(false);
        })
        .catch(error => console.log(error)); 
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          searchHandler();
        }
      };
    const pageChange = (pageNumber = 1) => {
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(ascDesc === true ? `${ENGRAVING_RS_URL}?engraving_status=${jobActiveStatus}&page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${ENGRAVING_RS_URL}?engraving_status=${jobActiveStatus}&page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
            .then(response => {
                setCurrentPage(response.data.allCylinders.current_page)
                // setPerPage(response.data.allCylinders.per_page)
                setStatus(response.data.engraving_status)
                setTotalData(response.data.allCylinders.total)
                setCylinderData(response.data.allCylinders.data)
                setEngravingStatus(response.data.engraving_status)
                setShiftExit(response.data.shiftExist)
                setIsLoading(false);
            })
            .catch(error => console.log(error))
    }

    const perPageBoxChange = (e) => {
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(ascDesc === true ? `${ENGRAVING_RS_URL}?engraving_status=${jobActiveStatus}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${ENGRAVING_RS_URL}?engraving_status=${jobActiveStatus}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
            .then(response => {
                setCurrentPage(response.data.allCylinders.current_page)
                // setPerPage(response.data.allCylinders.per_page)
                setStatus(response.data.engraving_status)
                setTotalData(response.data.allCylinders.total)
                setCylinderData(response.data.allCylinders.data)
                setEngravingStatus(response.data.engraving_status)
                setShiftExit(response.data.shiftExist)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    }
    useEffect(()=>{
        let ascUrl = '';
        if (ascDesc === true) {
            ascUrl = `${ENGRAVING_RS_URL}?engraving_status=${jobActiveStatus}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`;
        } else {
            ascUrl = `${ENGRAVING_RS_URL}?engraving_status=${jobActiveStatus}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`;
        }
        
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(ascUrl)
            .then(response => {
                setCurrentPage(response.data.allCylinders.current_page)
                // setPerPage(response.data.allCylinders.per_page)
                setStatus(response.data.engraving_status)
                setTotalData(response.data.allCylinders.total)
                setCylinderData(response.data.allCylinders.data)
                setEngravingStatus(response.data.engraving_status)
                setShiftExit(response.data.shiftExist)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    },[ascDesc])

    const sortHandler = (params) => {
        setAscDesc(prevAscDesc => !prevAscDesc);
        setSortParam(params)
        
    }
    const handleEngravingSchedule = ()=>{
        let url = `${process.env.PUBLIC_URL}/pendingEngravingSchedule`;
        window.open(url, '_blank', 'height=800,width=1200');
    }
    const handleShiftStatus = () => {
        if (shiftExit !== undefined) {
            SweetAlert.fire({ title: "Warning", text: "Please update Shift-status",icon: "warning" });
        }else{
            console.log('Click for shift not ready')
            SweetAlert.fire({ title: "Warning", text: "Please Selete Duty-Shift",icon: "warning" });
        }
    }
    // console.log(engravingStatus)

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Engrave List</h5>
                                    </div>
                                    <div className="col-md-6 text-right">
                                    <Link 
                                                to={{pathname: `${process.env.PUBLIC_URL}/engrave/ShiftList`, state: { params: {menuId: menuId} }}}
                                                className="btn btn-primary btn-sm" 
                                                type="button" 
                                                // onClick={searchHandler} 
                                            >Shift
                                            </Link>
                                        {/* <PanelRefreshIcons panelRefresh={pageChange} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-lg-3">
                                    <div className="input-group text-box searchBox">
                                        <input
                                            type="text"
                                            className="form-control input-txt-bx"
                                            placeholder="Type to Search..."
                                            onChange={(e) => handleSearchText(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="input-group-append">
                                            <button 
                                                className="btn btn-primary btn-sm" 
                                                type="button" 
                                                onClick={searchHandler} 
                                            >Go
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-lg-5">
                                    <div className="custom-table-pagination m-r-10 d-flex justify-content-between align-items-center">
                                        <label className="mt-3">
                                            <span>
                                                <select className="form-control pagi-select" name="engraving_status" onChange={(e) => setJobActiveStatus(parseInt(e.target.value))} value={jobActiveStatus} >
                                                    <option value="2">All Engrave</option>
                                                    <option value="0">Running Engrave</option>
                                                    <option value="1">Close Engrave</option>
                                                </select>
                                            </span>
                                        </label>
                                        
                                        <Button style={{marginTop:'8px'}} variant="primary" onClick={handleEngravingSchedule}> ENGRAVING SCHEDULE </Button>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <AddButton shiftExit={shiftExit} link="engraving/add" menuId={menuId} />
                                    <PerPageBox pageBoxChange={perPageBoxChange} perPage={perPage} setPerPage={setPerPage}/>
                                </div>
                            </div>
                                
                            <div className="card-body datatable-react">
                                {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <div className="table-responsive">
                                        <table className="table table-border-horizontal">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="5%" > SL.</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="10%" onClick={() => sortHandler(1)}><i className="fa fa-sort"></i> Cyl Id.</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="15%" onClick={() => sortHandler(2)}><i className="fa fa-sort"></i> Job Name</th>
                                                    {/* <th scope="col" width="15%" onClick={() => sortHandler(3)} ><i className="fa fa-sort"></i> Job Name</th> */}
                                                    <th style={{cursor: "pointer"}} scope="col" width="5%" onClick={() => sortHandler(3)}><i className="fa fa-sort"></i> FL</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="5%" onClick={() => sortHandler(4)}><i className="fa fa-sort"></i> Cir</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="5%" onClick={() => sortHandler(5)}><i className="fa fa-sort"></i> Dia</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="15%" onClick={() => sortHandler(6)}><i className="fa fa-sort"></i> Printer Name</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="10%" onClick={() => sortHandler(7)}><i className="fa fa-sort"></i> Plating Order</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="10%" onClick={() => sortHandler(8)}><i className="fa fa-sort"></i> FL</th>
                                                    <th style={{cursor: "pointer"}} scope="col" width="15%" onClick={() => sortHandler(9)}><i className="fa fa-sort"></i> Client</th>   
                                                    <th scope="col" width="10%"> Engraving</th>
                                                    {/* <th scope="col" width="15%" onClick={() => sortHandler(6)} > Printer</th>
                                                    <th scope="col" width="10%" > Per Sqr Amount</th> */}
                                                    {/* <th scope="col" width="7%">Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { 
                                                    cylinderData.length > 0 ? 
                                                        <>
                                                            {cylinderData.map((item, key) =>           
                                                                (
                                                                    <tr key={key}>
                                                                        <td scope="row">{ ((key+1) + (currentPage == 1 ? 0 : (currentPage*perPage - perPage))) }</td>
                                                                        <td>{item.cylinder_id}</td>
                                                                        <td>{item.job_name}</td>
                                                                        <td>{item.fl}</td>
                                                                        <td>{item.cir}</td>
                                                                        <td>{item.dia}</td>
                                                                        <td>{item.printer_name}</td>
                                                                        <td>{item.plating_order}</td>
                                                                        <td>{item.before_fl}</td>
                                                                        <td>{item.client_name}</td>
                                                                        <td>
                                                                            {engravingStatus == '0' ?
                                                                            (
                                                                                shiftExit !== undefined ? 
                                                                                ( // Check if shiftExit is available
                                                                                    (shiftExit?.running_status || shiftExit) === 1 ? 
                                                                                    (
                                                                                        <Link 
                                                                                        to={{
                                                                                            pathname: `${process.env.PUBLIC_URL}/engraving/add`,
                                                                                            state: { params: { menuId: menuId, jobNo: item.job_id, cylinder_no:item.cylinder_id } }
                                                                                        }}
                                                                                        className="btn btn-secondary btn-xs">
                                                                                            Engrave
                                                                                    </Link>
                                                                                    ) 
                                                                                    :
                                                                                     (
                                                                                    <button onClick={handleShiftStatus} className="btn btn-secondary btn-xs">
                                                                                        Engrave
                                                                                    </button>
                                                                                    )
                                                                                ) : (
                                                                                    <button onClick={handleShiftStatus} className="btn btn-secondary btn-xs">
                                                                                    Engrave
                                                                                    </button>
                                                                                )
                                                                                )
                                                                            

                                                                            // ============================================
                                                                            
                                                                             : 
                                                                             'Done'
                                                                             }
                                                                            
                                                                        </td>
                                                                        {/* <td className="">
                                                                            {
                                                                                accLoad === false && engravingStatus == '0' ? <>
                                                                                    {hasAccess.edit === true ? <EditButton link={`/engraving/edit/${item.id}`} menuId={ menuId } /> : ''} 
                                                                                </> : <div className="">
                                                                                    <Link to={{
                                                                                pathname: `${process.env.PUBLIC_URL}/engraving/edit/${item.id}`, 
                                                                                state: { params: {menuId: status,
                                                                                autoId:item.id} }
                                                                            }}>
                                                                                   <FaFileAlt size={25}/>
                                                                                    </Link>
                                                                                    </div>
                                                                            }
                                                                        </td> */}
                                                                    </tr>
                                                                )                
                                                            )}
                                                        </> 
                                                    : <tr><td colSpan="12" className="text-center">No data found</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination 
                                    activePage={currentPage}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={totalData}
                                    onChange={pageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    firstPageText="First"
                                    lastPageText="Last"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}