import React, { Fragment , useEffect, useReducer, useState } from 'react';
import ReportHeader from './ReportHeader';
// import {FACTORY_PRODUCTION_REPORT} from '../../../../api/userUrl'
import { userGetMethod } from '../../../../../api/userAction';
import './style.scss';
import { CURRENT_STOCK_REPORT, DEPARTMENTWISE_DETAILS_REPORT } from '../../../../../api/userUrl';
import { useLocation } from 'react-router-dom';

const Report = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataLoad, setDataLoad] = useState(true);
    const [totalFactoryProduction, setTotalFactoryProduction] = useState({});
    const [grindings, setGrindings] = useState([]);
    const [engravings, setEngravings] = useState([]);
    const [chromes, setChromes] = useState([]);
    const [layouts, setLayouts] = useState([]);
    const [platings, setPlatings] = useState([]);
    const [polishings, setPolishings] = useState([]);
    const [challans, setChallans] = useState([]);
    const [resultData, setResultData] = useState([]);
    
    const tableStyle = {
        "margin" : "2% 1% 2% 0%"
    }
    // const fromDate = props.match.params.toDate;
    // const toDate = props.match.params.toDate;
    const oneDay = 24 * 60 * 60 * 1000;
    const location = useLocation();

  // Parse the query string using URLSearchParams
  const queryParams = new URLSearchParams(location.search);
  const toDate = queryParams.get('to_date');   
  const fromDate = queryParams.get('from_date');   
  const type = queryParams.get('type');   
  const consumableUser = queryParams.get('consumeable_user');   
  
    useEffect(()=>{
        userGetMethod(`${DEPARTMENTWISE_DETAILS_REPORT}?type=${type}&from_date=${fromDate}&to_date=${toDate}&consumeable_user=${consumableUser}`) 
        .then(response => {
            console.log('response', response.data);
            setTotalFactoryProduction(response.data?.issue)
            setIsLoading(false);
        })
        .catch(error => console.log(error))
    }, []);
    

console.log("totalFactoryProduction",totalFactoryProduction)
// console.log(datas)

    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className=""> 
                            <ReportHeader totalFactoryProduction={totalFactoryProduction} />

                            <div className="mainDiv" style={{"padding": "1% 5%"}}>
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <>
                                        <div className="text-center">
                                            <h5 style={{fontWeight:'bold'}}>{'Current Stock Report - '+toDate}</h5>
                                        </div>
                                        <Fragment>
                                            <div className="row">
                                                <table className="particulars table table-bordered table-stripped reportBody" cellSpacing="5" cellPadding="5" width="100%"  style={tableStyle}>
                                                    <thead>    
                                                        <tr>
                                                            <th style={{fontSize:"15px",fontWeight:"bold"}} className="text-center">Issue No</th>
                                                            <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">Issue Date</th>
                                                            <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">ProductId</th>
                                                            <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">Description</th>
                                                            <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">Quantity</th>
                                                            <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">Unit Price</th>
                                                            <th style={{fontSize:"15px",fontWeight:"bold"}}  className="text-center">Total</th>
                                                              
                                                        </tr>
                                                            
                                                          
                                                    </thead>
                                                    {isLoading ?
                                                    (<p>loading...</p>)
                                                    :
                                                    (
                                                        
                                                       <>
                                                       {totalFactoryProduction.length> 0 ? 
                                                        (
                                                            totalFactoryProduction.map((item,index)=>
                                                                
                                                                  
                                                                
                                                                    <>
                                                                    <thead>
                                                                
                                                                <td id='dateId' style={{ fontSize:"13px",fontWeight:"bold"}}  key={index}>{item.slip_no}</td>
                                                                <td style={{ fontSize:"13px",fontWeight:"bold"}}  key={index}>{item.tr_date.split(" ")[0]}</td>
                                                                <td style={{ fontSize:"13px",fontWeight:"bold",textAlign:'center'}}  key={index}>{item.product_id}</td>
                                                                <td style={{ fontSize:"13px",fontWeight:"bold",textAlign:'right'}}  key={index}>{item.product_name}</td>
                                                                <td style={{ fontSize:"13px",fontWeight:"bold",textAlign:'right'}}  key={index}>{item.total_stock}</td>
                                                                <td style={{ fontSize:"13px",fontWeight:"bold",textAlign:'right'}}  key={index}>{item.unit_price}</td>
                                                                <td style={{ fontSize:"13px",fontWeight:"bold", textAlign:'right'}}  key={index}>{item.total_stock * item.unit_price}</td>
                                                            
                                                            
                                                                    </thead>
    
                                                                    
    
                                                                    </>
                                                            )
                                                        
                                                        )
                                                        :"There is no data in current stock"}
                                                       </>
                                                       
                                                
                                                )}
                                                
                                                </table>
                                            </div>    
                                        </Fragment>
                                    </>
                                )
                            } 
                            </div>  
                        </div>    
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default Report;