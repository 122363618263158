import React, { Fragment, useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useForm from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
// import { PanelRefreshIcons, SubmitButton } from '../../../common/GlobalButton';
import { userGetMethod, userPostMethod, userPutMethod } from "../../../../api/userAction";
import {
  PURCHASE_ORDER_URL,
} from "../../../../api/userUrl";
import SweetAlert from "sweetalert2";
// import { placeHolderText } from '../../common/GlobalComponent';
// import { trStyleNormal } from '../jobAgreement/Create';
import moment from "moment";
import { SubmitButton } from "../../common/GlobalButton";
import Swal from "sweetalert2";

const Add = (props) => {
  const { handleSubmit, register, errors, reset } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownData, setDropdownData] = useState({});
  const [typeheadOptions, setTypeheadOptions] = useState({});
  const [clientStockDetails, setClientStockDetails] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [branches, setBranches] = useState("");
  const [units, setUnits] = useState();
  const [typeAheadValue, setTypeAheadValue] = useState({
    reference_job: [],
    job_sub_class_id: [],
    client_id: [],
    printer_id: [],
    marketing_person_id: [],
    design_machine_id: [],
  });

  let [jobOrderData, setJobOrderData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      project_no: "",
      branches: "",
      pno: "",
      ref_date: "",
      product_id: "",
      remarks: "",
      unit: "",
      job_order_qty_limit: 0,
      orderQty: 0,
      quantity: "",
      status:"",
      receive_date: moment().format("YYYY-MM-DD"),
    }
  );
  let job_order_id = props.location.state.params.job_order_id
    ? props.location.state.params.job_order_id
    : null;
    var menuId = 0;
    let purchase_id = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    }else{
        menuId = props.location.state.params.menuId;
        purchase_id = props.location.state.params.menuId.purchase_id;
    }
  useEffect(() => {
    setIsLoading(true);
        // pageRefreshHandler(job_order_id);
        userGetMethod(`${PURCHASE_ORDER_URL}/create`).then((response) => {
        setBranches(response.data.branches);
        setUnits(response.data.consumeableUnits);
        // FOR JOB SUB CLASS
        let productOptions = [];
        if (response.data.products && response.data.products.length > 0) {
            response.data.products.map((subClass) => {
            let productObj = {};
            productObj.id = subClass.id;
            productObj.name = `[${subClass.id}]-${subClass.product_name}`;
            productObj.unit = subClass.unit;
            productOptions.push(productObj);
            });
        }

        setTypeheadOptions((prevstate) => ({
            ...prevstate,
            ["product_id"]: productOptions,
        }));
        setIsLoading(false);

    });
    if (purchase_id !== 0 && purchase_id !== undefined) {
        userGetMethod(`${PURCHASE_ORDER_URL}/${purchase_id}/edit`).then((response) => {
            const data = response.data.purchaseOrder;
            setClientStockDetails(data.details);
            setJobOrderData({
                project_no: "",
                branches: data.dig_branch.id,
                pno: data.pno,
                ref_date: data.ref_date,
                product_id: "",
                remarks: "",
                unit: "",
                job_order_qty_limit: 0,
                orderQty: 0,
                quantity: "",
                status : data.status,
            });
        })
    }
    setIsLoading(false);
  }, [purchase_id]);

  // FOR Typeahead DATA INPUT
  const dropDownChange = (event, stateName) => {
    if (event.length > 0) {
      const selectedValue = event[0].id;
      const selectedValueName = event[0].name;
      setSelectedValue(event);
      setDropdownData((prevstate) => ({
        ...prevstate,
        ["product_id"]: selectedValue,
        ["product_name"]: selectedValueName,
        ["product_unit"]: event[0].unit,
      }));
      setJobOrderData({ 
        ["product_id"]: selectedValue,
        ["product_name"]: selectedValueName,
        ["unit"]: event[0].unit,

       })
    }
  };
  // FOR CLIENT STOCKS DATA INPUT
  const clientStocksInputHander = (event) => {
    setJobOrderData({ [event.target.name]: event.target.value });
  };

  console.log("job order",jobOrderData.status)

  useEffect(() => {
    const fl = parseFloat(jobOrderData.fl);
    const cir = parseFloat(jobOrderData.cir);
    const qty = parseFloat(jobOrderData.qty);
    setJobOrderData({
      surface_area: parseFloat(fl * cir).toFixed(2),
      total_surface_area: parseFloat(qty * fl * cir).toFixed(2),
    });
  }, [jobOrderData.fl, jobOrderData.cir, jobOrderData.qty]);

  // FOR CLIENT STOCKS ARRAY READY
  const addOrderDetailsHandler = (event) => {
    let { quantity, product_name, pno, ref_date, product_id, remarks, unit } =
      jobOrderData;
      const unitName = (units.find((item) => item.id === Number(unit)) || {}).unit_name || "No matching unit found";

    if (quantity) {
      // OBJECT CREATE & PUSH IN AN ARRAY
      let clientStockDetails_arr = [];
      let clientStockDetails_obj = {};
      clientStockDetails_obj.product_name = product_name;
      clientStockDetails_obj.pno = pno;
      clientStockDetails_obj.ref_date = ref_date;
      clientStockDetails_obj.quantity = quantity;
      clientStockDetails_obj.product_id = product_id;
      clientStockDetails_obj.remarks = remarks;
      clientStockDetails_obj.unit = unit;
      clientStockDetails_obj.unitName = unitName;

      clientStockDetails_arr.push(clientStockDetails_obj);
      // setClientStockDetails([...clientStockDetails, ...clientStockDetails_arr]);
// =============================================
      setClientStockDetails((prevStockDetails) => {
        // Check if the product_id already exists in the state
        const existingProduct = prevStockDetails.find(
          (product) => product.product_id === product_id
        );

        if (existingProduct) {
          // Update the existing product's quantity and unit_price
          return prevStockDetails.map((product) =>
            product.product_id === product_id
              ? {
                  ...product,
                  quantity: Number(product.quantity) + Number(quantity), // Add new quantity to existing
                  unit: Number(unit),
                  unitName: unitName,
                  remarks: remarks,
                  product_name:product_name,
                  ref_date: ref_date,
                  pno: pno
                }
              : product
          );
        } else {
          // Add a new product to the state
          let clientStockDetails_obj = {
            product_id,
            quantity,
            unit,
            unitName,
            ref_date,
            remarks,
            product_name,
            pno
          };

          return [...prevStockDetails, clientStockDetails_obj];
        }
      });

      // =====================
      setDropdownData({});
      setSelectedValue([])
      setJobOrderData({
      product_id: "",
      remarks: "",
      unit: "",
      orderQty: 0,
      quantity: "",
      receive_date: moment().format("YYYY-MM-DD"),
      });
      // clearFormField();
    }
    else{
      SweetAlert.fire({ title: "Warning", text: "Please fill all input!", icon: "warning" });
      setDropdownData({});
      setSelectedValue([]);
      setJobOrderData({
        product_id: "",
        remarks: "",
        unit: "",
        orderQty: 0,
        quantity: "",
        receive_date: moment().format("YYYY-MM-DD"),
        });
    }
  };
  // FOR REMOVE CLIENT STOCKS SINGLE DATA FROM CLIENT STOCKS ARRAY
  const removeClientStockHandler = (product_id, thisRowQty) => {
    //console.log(supplierId);
    // setJobOrderData({
    //     orderQty: parseInt(jobOrderData.orderQty) - parseInt(thisRowQty)
    // })
    let availableClientStock = clientStockDetails.filter(
      (item) => item.product_id != product_id
    );
    // let availableClientStock = clientStockDetails.filter((item) => item.supplier_id != supplierId );
    setClientStockDetails([...availableClientStock]);
  };
  // FINALLY SUBMIT FOR SAVE TO SERVER
  const submitHandler = (data, e) => {
    data.purchaseOrderArr = clientStockDetails;
    if (clientStockDetails.length > 0 ) {
        

        Swal.fire({
          title: "Are you sure?", 
          text: data.status === 1 ? "want to save to list with status set Final!" : "want to save to list!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, proceed!",
          cancelButtonText: "No, cancel!"
        }).then((result) => {
          if (result.isConfirmed) {
            if (purchase_id !==0 && purchase_id !== undefined) {
              userPutMethod(`${PURCHASE_ORDER_URL}/${purchase_id}`, data)
                          .then(response => {
                              if (response.data.status == 1) {
                                  toast.success(response.data.message)
                                  clearFormField(e);
                                  e.target.reset();
                              } else {
                                  toast.error(response.data.message)
                              }
                          })
          }else{
              userPostMethod(PURCHASE_ORDER_URL, data)
              .then(response => {
                  console.log(response);
                  if (response.data.status == 1) {
                      toast.success(response.data.message)
                      clearFormField(e);
                      e.target.reset();
                  } else {
                      toast.error(response.data.message)
                  }
              })
              .catch(error => toast.error(error))
          }
          } else {
            // If user clicks "No"
            console.log("User canceled! Reverting...");
          }
        });
    } else {
        SweetAlert.fire({ title: "Warning", text: "Please create purchase order!", icon: "warning" });
    }
  };
  const clearFormField = (event) => {
    setJobOrderData({
        project_no: "",
        branches: "",
        pno: "",
        ref_date: "",
        product_id: "",
        remarks: "",
        unit: "",
        job_order_qty_limit: 0,
        orderQty: 0,
        qty: "",
        status:'',
        receive_date: moment().format("YYYY-MM-DD"),
      });
    setSelectedValue([]);
    setClientStockDetails([]);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-6">
                    <h5>Purchase Order Add</h5>
                  </div>
                  <div className="col-md-6">
                    {/* <PanelRefreshIcons panelRefresh={pageRefreshHandler} /> */}
                  </div>
                </div>
              </div>
              <div className="card-body">
                {isLoading ? (
                  <img
                    src={process.env.PUBLIC_URL + "/preloader.gif"}
                    alt="Data Loading"
                  />
                ) : (
                  <form
                    onSubmit={handleSubmit(submitHandler)}
                    className="theme-form"
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label" htmlFor="">
                            Branches
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              id="pno"
                              name="pno"
                              onChange={clientStocksInputHander}
                              ref={register({
                                required: "select one option",
                              })}
                              value={jobOrderData?.pno || ""}
                              // {...register("unit", { required: true })}
                            >
                              <option value="">Select a branch</option>
                              {branches &&
                              Array.isArray(branches) &&
                              branches.length > 0 ? (
                                branches.map((option, index) => (
                                  <option key={index} value={option.id}>
                                    {option.branch_name}
                                  </option>
                                ))
                              ) : (
                                <option value="" disabled>
                                  No branch available
                                </option>
                              )}
                            </select>
                            {errors && (
                              <p className="text-danger">{errors.message}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      

                      <div className="col-md-4">
                        <div className="form-group row">
                          <label
                            className="col-sm-4 col-form-label required"
                            htmlFor=""
                          >
                            PO date
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              id=""
                              name="ref_date"
                              value={
                                jobOrderData.ref_date
                                  ? jobOrderData.ref_date
                                  : moment().format("YYYY-MM-DD")
                              }
                              disabled
                              onChange={clientStocksInputHander}
                              required
                              type="date"
                              ref={register({
                                
                              })}
                            />
                            {errors && (
                              <p className="text-danger">{errors.message}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group row">
                          <label
                            className="col-sm-4 col-form-label required"
                            htmlFor=""
                          >
                            Status
                          </label>
                          <div className="col-sm-8">
                          <select
                              className="form-control"
                              id="status"
                              name="status"
                              onChange={clientStocksInputHander}
                              ref={register({
                                required: "select one option",
                              })}
                              value={jobOrderData?.status ?? ""}
                              {...register("status", { required: true })}
                            >
                              <option value="">Select a status</option>
                              <option value={0}>Pending</option>
                              <option value={1}>Final</option>
                              
                            </select>
                            {errors && (
                              <p className="text-danger">{errors.message}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row m-t-10 m-l-10">
                      <fieldset className="border" style={{ width: "98%" }}>
                        <legend className="w-auto text-left">
                          Purchase Order Details
                        </legend>
                        <div className="col-md-12">
                          <div className="form-row">
                            <div className="col-md-2 mb-3">
                              <label for="">Product ID</label>
                              <Typeahead
                                id="product_id"
                                name="product_id"
                                labelKey={(option) => `${option.name}`}
                                options={typeheadOptions["product_id"] || []}
                                placeholder="Select product..."
                                onChange={(e) =>
                                  dropDownChange(e, "product_id")
                                }
                                inputProps={{ }}
                                selected={selectedValue?.length ? selectedValue : []}
                                // ref={register({
                                //     required: 'Sub Class Field Required'
                                // })}
                                {...register("product_id")}
                              />
                            </div>
                            

                            <div className="col-md-2 mb-3">
                              <label for="">Unit</label>
                              {/* <input
                                className="form-control"
                                id=""
                                name="unit"
                                type="text"
                                placeholder=""
                                onChange={clientStocksInputHander}
                                value={dropdownData ? dropdownData.product_unit : jobOrderData.unit}
                              /> */}
                              <select
                                className="form-control"
                                id="unit"
                                // name="unit"
                                onChange={clientStocksInputHander}
                                ref={register({
                                  
                                })}
                                disabled={jobOrderData?.unit ? true : false}
                                // value={dropdownData ? dropdownData.product_unit:jobOrderData?.unit || ""}
                                value={jobOrderData?.unit || ""}
                                // {...register("unit", { required: true })}
                              >
                                <option value="">Select a unit</option>
                                {units &&
                                Array.isArray(units) &&
                                units.length > 0 ? (
                                  units.map((option, index) => (
                                    <option key={index} value={option.id}>
                                      {option.unit_name}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled>
                                    No units available
                                  </option>
                                )}
                              </select>
                            </div>
                            <div className="col-md-2 mb-3">
                              <label for="qty">Qty</label>
                              <input
                                className="form-control"
                                id="quantity"
                                name="quantity"
                                type="number"
                                placeholder="quantity"
                                onChange={clientStocksInputHander}
                                value={jobOrderData.quantity}
                                min="0"
                                onKeyDown={(e) => e.key === "-" && e.preventDefault()}
                              />
                            </div>

                            <div className="col-md-2 mb-3">
                              <label for="">Remark</label>
                              <input
                                className="form-control"
                                id=""
                                name="remarks"
                                type="text"
                                placeholder="remark.."
                                onChange={clientStocksInputHander}
                                value={jobOrderData.remarks}
                              />
                            </div>

                            <input
                              className="form-control"
                              id="client_name"
                              name="client_name"
                              type="hidden"
                              onChange={clientStocksInputHander}
                              value={jobOrderData.client_name}
                            />
                            <input
                              className="form-control"
                              id="id"
                              name="id"
                              type="hidden"
                              onChange={clientStocksInputHander}
                              value={jobOrderData.id}
                            />
                            <input
                              className="form-control"
                              id="client_id"
                              name="client_id"
                              type="hidden"
                              onChange={clientStocksInputHander}
                              value={jobOrderData.client_id}
                            />
                            <input
                              className="form-control"
                              id="printer_id"
                              name="printer_id"
                              type="hidden"
                              onChange={clientStocksInputHander}
                              value={jobOrderData.printer_id}
                            />

                            <div className="col-md-1 mb-4 m-t-5">
                              <span
                                className="btn btn-primary btn-sm mr-1 m-t-20"
                                type="add"
                                onClick={addOrderDetailsHandler}
                              >
                                Add
                              </span>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>

                    <div className="row m-t-10 m-l-10">
                      <fieldset className="border" style={{ width: "98%" }}>
                        <legend className="w-auto text-left">
                          Added Purchase Order
                        </legend>
                        <div className="col-md-12">
                          <table
                            className="table table-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                {/* <th scope="col" width="15%">Issue To</th> */}
                                {/* <th scope="col" width="20%">Job Ref No</th> */}
                                <th scope="col" width="10%">
                                  Product ID
                                </th>
                                <th scope="col" width="20%">
                                  Product Name
                                </th>
                                <th scope="col" width="15%">
                                  Quantity
                                </th>
                                <th scope="col" width="10%">
                                  Remark
                                </th>
                                <th scope="col" width="10%">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {clientStockDetails.length > 0 ? (
                                <>
                                  {clientStockDetails.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.product_id}</td>
                                      <td>{item.product_name}</td>
                                      <td>{item.quantity}</td>
                                      <td>{item.remarks}</td>

                                      <td align="center">
                                        <span
                                          onClick={() =>
                                            removeClientStockHandler(
                                              item.product_id,
                                            )
                                          }
                                        >
                                          <i
                                            className="icon-close"
                                            style={{
                                              width: 25,
                                              fontSize: 16,
                                              padding: 0,
                                              color: "#e4566e",
                                              cursor: "pointer",
                                            }}
                                          ></i>
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                <tr>
                                  <td colSpan="6" className="text-center">
                                    No data Added
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </fieldset>
                    </div>

                    <SubmitButton
                      link="consumablePurchaseOrder/index"
                      menuId={menuId}
                      offset="4"
                    />
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Add;
